import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import CTA from "../components/common/CTA"
import Frame from "../components/common/frame"
import PopUpForm from "../components/common/PopUpForm"
import SVGIcon from "../components/common/SVGIcon"
import Navigation from "../components/navigation"
import Slide from "../components/utility/slide_content"
import { ShowAllCards } from "../components/webinar/central-page-cards"
import EventTabs from "../components/webinar/event-tabs"
import WebinarDropDown from "../components/webinar/webinar-dropdown"
import "../styles/pages/central-page.scss"

function CentralPage({ pageContext }) {
  const { page } = pageContext
  const [currentCategory, setCurrentCategory] = useState("all")
  const [currentMainCategory, setCurrentMainCategory] = useState("")
  let navBgColor = page.hero.backgroundColor
  navBgColor = navBgColor.slice(
    navBgColor.indexOf("#"),
    navBgColor.indexOf("#") + 7
  )
  const [upcoming, setUpcoming] = useState(false)
  const [eventName, setEventName] = useState("")
  const [popupVisibility, setPopupVisibility] = useState(false)
  const togglePopup = name => {
    setEventName(name)
    setPopupVisibility(!popupVisibility)
  }

  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])
  var sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a valid name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      required: false,
      errorMessage: "Please enter company",
    },
    {
      name: "phone",
      type: "phone",
      label: "Mobile number",
      required: false,
      errorMessage: "Please enter a mobile number",
    },
  ]

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1.96,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 1.2,
    },
    mobile: {
      breakpoint: { max: 767, min: 500 },
      items: 1.2,
    },
    mobile1: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  }
  return (
    <>
      <StaticQuery
        query={graphql`
          query webinarAndEvents {
            SuperOps {
              tags(where: { pageName: Webinar }, orderBy: order_ASC) {
                name
                slug
              }
              webinars(first: 250) {
                pageName
                category
                mainCategory
                slug
                heroHeading
                videoLink
                date
                time
                name
                order
                coverImage {
                  url
                }
              }
            }
          }
        `}
        render={data => {
          const allWebinars = data.SuperOps.webinars
            .filter(x => x.pageName === "Webinar")
            .reverse()
          const allEvents = data.SuperOps.webinars.filter(
            x => x.pageName === "Events"
          )

          return (
            <div className="central-page">
              <Frame seo={page.seo}>
                <header>
                  <Navigation
                    page={"Home"}
                    darkHelloBar
                    theme="dark"
                    darkTheme
                    btn1="secondary white"
                    btn2="primary white ml-9"
                    newButton
                  />
                </header>

                <div>
                  <PopUpForm
                    visibility={popupVisibility}
                    togglePopup={val => {
                      setPopupVisibility(val)
                    }}
                    formData={formdata}
                    endpoint={process.env.HUBSPOT_EVENTS_CENTRAL_ENDPOINT}
                    url={process.env.HUBSPOT_EVENTS_CENTRAL_URL}
                    formName="Events central - Superops"
                    successMsg="Thank you!"
                    additionalFormProps={{ IpStackData: true }}
                    extradata={{ name: "events_name", value: eventName }}
                  />
                </div>

                <div
                // css={css`
                //   background: ${page.hero.backgroundColor};
                // `}
                >
                  <section className="hero-container-central">
                    <div
                      className="hero-section d-flex align-items-center text-center flex-column "
                      style={
                        upcoming
                          ? { minHeight: "797px", marginBottom: "-290px" }
                          : {}
                      }
                    >
                      <div className="content-wrapper Layout-container-big">
                        <div className="title-wrapper col-lg-9">
                          {parse(page.hero.heading.html)}
                        </div>

                        <p className="sub-title col-lg-6">
                          {page.hero.content.text}
                        </p>
                        <div className="pink-line mx-auto mt20" />
                        {/* <Col lg={3} className="d-dsk">
                          <h5 className="font-zodiak text-white text-center fw-bold mb50 mt50">
                            Upcoming webinar
                          </h5>
                        </Col> */}
                      </div>
                    </div>
                  </section>

                  {/* <section className="hero pt80">
                    <Container className="Layout-container text-center">
                      <h1 className="font-roboto fw-800 heading mx-auto mb30 down-to-up-1">
                        {page.hero.heading.text}
                      </h1>
                      <p className="fw-500 p18 description mx-auto down-to-up-2">
                        {page.hero.content.text}
                      </p>
                    </Container>
                  </section> */}

                  <section
                    className={`${
                      page.pageName === "Webinars page" ? "webinars" : "events"
                    }`}
                  >
                    {page.newfield.map((field, ind) => {
                      switch (field.__typename) {
                        case "SuperOps_Card": {
                          if (field.cardName === "Upcoming") {
                            const parseDateRange = dateRange => {
                              const match = dateRange.match(/([A-Za-z]+) (\d+)/) // Extracts month and day
                              if (!match) return null

                              const [_, monthStr, day] = match
                              const year = dateRange.match(/\d{4}/)?.[0]
                              if (!year) return null

                              const month =
                                new Date(`${monthStr} 1, 2000`).getMonth() + 1 // Convert month to number (1-based)
                              return new Date(
                                `${year}-${String(month).padStart(
                                  2,
                                  "0"
                                )}-${day}`
                              ) // Convert to ISO
                            }
                            const today = new Date()
                            const nextMonthLastDay = new Date(
                              today.getFullYear(),
                              today.getMonth() + 2,
                              0
                            ) // Get last day of next month

                            const upcomingWebinars =
                              page.pageName === "Webinars page"
                                ? allWebinars.filter(
                                    x => new Date(x.date) >= new Date()
                                  )
                                : allEvents.filter(x => {
                                    const eventDate = parseDateRange(x.date)
                                    return (
                                      eventDate &&
                                      eventDate >= today &&
                                      eventDate <= nextMonthLastDay
                                    ) // only allows the events from upcoming month
                                  })
                            if (upcomingWebinars.length > 0) {
                              setUpcoming(true)
                            }
                            let hideCarouselArr = false
                            if (screenWidth < 992) {
                              if (upcomingWebinars.length < 2)
                                hideCarouselArr = true
                            } else {
                              if (upcomingWebinars.length < 3)
                                hideCarouselArr = true
                            }
                            return (
                              <>
                                {upcomingWebinars.length ? (
                                  <section className="upcoming-section">
                                    <Container>
                                      <p className="upcoming-word">
                                        Upcoming events
                                      </p>
                                      <Slider {...sliderSettings}>
                                        {upcomingWebinars.map((data, i) => {
                                          const {
                                            category,
                                            heroHeading,
                                            date,
                                            time,
                                            coverImage,
                                            pageName,
                                          } = data
                                          return (
                                            <div className="upcoming-div">
                                              <Col lg={5} className="left">
                                                <div className="heading-wrapper">
                                                  {pageName ===
                                                  "Webinars page" ? (
                                                    <p className="tag">
                                                      {category}
                                                    </p>
                                                  ) : null}
                                                  <h2 className="heading">
                                                    {heroHeading}
                                                  </h2>
                                                </div>
                                                <div className="d-flex gap-32 flex-wrap">
                                                  <div className="d-flex align-items-center gap-8">
                                                    <SVGIcon name="webinar/calendar" />
                                                    <p className="fw-500 m-0 p14">
                                                      {date}
                                                    </p>
                                                  </div>
                                                  <div className="d-flex align-items-center gap-8">
                                                    <SVGIcon
                                                      name={`webinar/${
                                                        upcomingWebinars.pageName ===
                                                        "Webinars page"
                                                          ? "clock"
                                                          : "location"
                                                      }`}
                                                    />
                                                    <p className="fw-500 m-0 p14">
                                                      {time}
                                                    </p>
                                                  </div>
                                                </div>
                                              </Col>
                                              <Col lg={5} className="right">
                                                <div className="img-wrapper">
                                                  <img
                                                    src={coverImage.url}
                                                    className="w-100"
                                                    alt="img"
                                                  />
                                                </div>
                                              </Col>
                                            </div>
                                          )
                                        })}
                                      </Slider>
                                    </Container>
                                  </section>
                                ) : null}
                              </>
                            )
                          } else {
                            return (
                              <section
                                className={`${
                                  page.pageName === "Webinars page" ||
                                  "Events page"
                                    ? "vertical-dropdowns"
                                    : "horizontal-tabs"
                                } down-to-up-5 on-demand-webinars mx-auto`}
                              >
                                <Container className={`container-box`}>
                                  <div className="inside-wrapper">
                                    {/* {field.text[0] ? (
                                      <p className="p24 font-roboto text-pink fw-600 mb50 pt50">
                                        {field.text[0]}
                                      </p>
                                    ) : null} */}
                                    <div className="row d-flex flex-wrap justify-content-between">
                                      <Col
                                        lg={
                                          page.pageName === "Webinars page" ||
                                          "Events page"
                                            ? 3
                                            : 12
                                        }
                                        className={`left track-category ${page.pageName ===
                                          "Webinars page" ||
                                          ("Events page" && "sticky-top")}`}
                                      >
                                        <div
                                          className={`${page.pageName ===
                                            "Webinars page" ||
                                            ("Events page" && "sticky-top")}`}
                                        >
                                          {page.pageName === "Webinars page" ? (
                                            <WebinarDropDown
                                              currentTag={currentCategory}
                                              selectTag={category =>
                                                setCurrentCategory(category)
                                              }
                                              mainCategory={main =>
                                                setCurrentMainCategory(main)
                                              }
                                              allTags={data.SuperOps.tags}
                                            />
                                          ) : (
                                            <EventTabs
                                              currentTag={currentCategory}
                                              selectTag={category =>
                                                setCurrentCategory(category)
                                              }
                                            />
                                          )}
                                          {console.log(
                                            "currentCategory",
                                            currentCategory
                                          )}
                                        </div>
                                      </Col>
                                      <Col
                                        lg={
                                          page.pageName === "Webinars page"
                                            ? 8
                                            : 9
                                        }
                                        className="right"
                                      >
                                        <Row className="webinar-card-wrapper flex-wrap">
                                          <ShowAllCards
                                            category={currentCategory}
                                            allCards={
                                              page.pageName === "Webinars page"
                                                ? allWebinars.filter(
                                                    x => x.videoLink
                                                  )
                                                : allEvents
                                            }
                                            pageName={page.pageName}
                                            togglePopup={name =>
                                              togglePopup(name)
                                            }
                                            currentMainCategory={
                                              currentMainCategory
                                            }
                                            colLg={
                                              page.pageName === "Webinars page"
                                                ? 6
                                                : 4
                                            }
                                            buttonText={
                                              page.pageName === "Webinars page"
                                                ? "access"
                                                : "learn more"
                                            }
                                          />
                                        </Row>
                                      </Col>
                                    </div>
                                  </div>
                                </Container>
                              </section>
                            )
                          }
                        }
                        case "SuperOps_CtaFeature": {
                          return (
                            <section className="mt80">
                              <Slide delay="200">
                                <CTA
                                  data={[field.ctaBox]}
                                  className="Layout-container"
                                  lgLeft={7}
                                  lgRight={4}
                                  rightStyles={{ alignItems: "center" }}
                                  newButton
                                  newDesign
                                />
                              </Slide>
                            </section>
                          )
                        }
                      }
                    })}
                  </section>
                </div>
              </Frame>
            </div>
          )
        }}
      />
    </>
  )
}

export default CentralPage
