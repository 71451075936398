import React, { useEffect, useState } from "react"
import { pageScroller, scrollPosition } from "../utility/scroll-position"

let EventDropDown = props => {
  const [scrollValue, setScrollValue] = useState()
  const { currentTag, selectTag } = props
  const mainCategories = ["SuperOps Events", "Other Events"]
  const [screenWidth, setScreenWidth] = useState(0)

  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    setScrollValue(
      scrollPosition(
        ".track-category",
        screenWidth < 500 ? ".brand-nav-light-mob" : ".brand-nav-light",
        screenWidth < 500 ? 40 : 400
      )
    )
  }, [screenWidth])

  const selectCategory = item => {
    selectTag(item)
    pageScroller(scrollValue)
  }

  return (
    <div className="categories event-categories">
      <div className="taglist-desk-event">
        <div className="alt">
          <div
            className={`tagName cursor-pointer ${
              currentTag === "all" ? "active" : ""
            }`}
          >
            <p
              onClick={() => selectCategory("all")}
              className={`font-zodiak fw-bold tag ${
                currentTag === "all" ? "active" : ""
              }`}
            >
              All
            </p>
          </div>
          <div>
            {mainCategories.map((item, id) => (
              <div
                key={id}
                className={`d-flex justify-content-between align-items-center cursor-pointer mb24 mt40 tagName ${
                  currentTag === item ? "active" : ""
                }`}
                onClick={() => selectCategory(item)}
              >
                <p
                  className={`font-zodiak  tag fw-bold m-0 ${
                    currentTag === item ? "active" : ""
                  }`}
                >
                  {item}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventDropDown
